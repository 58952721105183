import PropTypes from 'prop-types';
import { Container, Hidden } from '@material-ui/core';

import useLocalized from 'CustomHooks/useLocalized';
import VehicleHireIcon from 'Screens/Main/Sections/Services/Assets/VehicleHireIcon';
import BookDriverForm from 'Screens/Main/Sections/Header/Blocks/BookDriverForm/BookDriverForm';
import ImagePresenter from 'Components/ImagePresenter/ImagePresenter';
import VehicleElement from 'Screens/Services/Blocks/VehicleElement/VehicleElement';
import { VehicleHireBackground, leyland_daf, vauxhall_movano } from 'Screens/Services/Assets';

const VEHICLE_LIST = [{
  id: 'leyland_daf',
  image: leyland_daf,
  title: '26 Tonne Dropside Truck (Leyland DAF) Hire with Driver',
  properties: [
    'flatbed',
    'brick grab',
    'loading capacity: 12 ton',
    'maximum reach: 7.5m',
  ],
  subDescription: `
    <b>MONDAY to FRIDAY</b> <br>
    Daily charge : £500 (for 8 hours work) <br>
    Work hours: 8.00 am – 4.00 pm = 8 hours work <br>
    Overtime: after 4.00 pm overtime will be charged for every started hour with the rate of £30/hour <br>
    Extra Fuel Charge: Daily charge includes diesel up to 100 km/day, over 100km there will be a charge of 50p/km <br>
    <br>
    <b>SATURDAY to SUNDAY</b> <br>
    Extra £60 will be charged <br>
  `,
}, {
  id: 'vauxhall_movano',
  image: vauxhall_movano,
  title: 'Vauxhall Movano',
  properties: [
    '3.5T',
    'inside loading up to 3.8 metre items',
    'High 2 meter',
    'roof racking for loading up to 6 metre items',
    'loading capacity 1.4 tonne'
  ],
  subDescription: `
    <b>MONDAY to FRIDAY</b> <br>
    Daily charge : £185 (for 9 hours work) <br>
    Work hours: 7.00 am – 4.00 pm = 9 hours work <br>
    Overtime: after 4.00 pm overtime will be charged for every started hour with the rate of £18/hour <br>
    Extra Fuel Charge: Daily charge includes diesel up to 100 km/day, over 100km  there will be a charge of 20p/km <br>
    <br>
    <b>SATURDAY to SUNDAY</b> <br>
    Extra £50 will be charged <br>
  `,
}];

const VehicleHireScreen = ({
  branchLocationOptions,
}) => {
  return (
    <>
      <div className="service-detail-screen">
        <Container className="service-detail-screen__container">
          <div className="service-detail-screen__left">
            <div className="service-detail-screen__left--title">
              {useLocalized('services_vehicle_hire')}
              <VehicleHireIcon className="service-detail-screen__left--title--icon" />
            </div>
            <div className="service-detail-screen__left--description">
              {useLocalized('services_driver_hire_description')}
            </div>

            <div className="service-detail-screen__left--content">
              {VEHICLE_LIST.map(vehicle => <VehicleElement key={vehicle.id} vehicle={vehicle} />)}
            </div>
            <div className="service-detail-screen__left--sub-description">
              {useLocalized('not_include_vat')}
            </div>
          </div>
          <div className="service-detail-screen__right">
            <BookDriverForm branchLocationOptions={branchLocationOptions} className="service-detail-screen__right--form" />
          </div>
        </Container>
        <div className="service-detail-screen-background-layer">
          <div className="service-detail-screen-background-layer__left"></div>
          <div className="service-detail-screen-background-layer__right" style={{ backgroundImage: `url(${VehicleHireBackground})` }}/>
        </div>
        <Hidden mdUp>
          <ImagePresenter
            containerClass="service-detail-screen__mobile-image-container"
            imageClass="service-detail-screen__mobile-image-container--image"
            src={VehicleHireBackground}
          />
        </Hidden>
      </div>
    </>
  );
}

VehicleHireScreen.propTypes = {
  branchLocationOptions: PropTypes.object,
};

export default VehicleHireScreen;
