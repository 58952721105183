import L from 'leaflet';

import markerSvg from 'Screens/Main/Sections/Contact/Blocks/Map/Components/marker.svg';

const MarkerIcon = new L.Icon({
    iconUrl: markerSvg,
    iconRetinaUrl: markerSvg,
    iconAnchor: null,
    tooltipAnchor: [0, -40],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
});

export default MarkerIcon;
