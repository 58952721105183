import {
  MapContainer,
  Marker,
  Tooltip,
  TileLayer,
} from 'react-leaflet';

import MarkerIcon from 'Screens/Main/Sections/Contact/Blocks/Map/Components/MarkerIcon';
import { COMPANY_LOCATION, COMPANY_ADDRESS } from 'Constants/Options';
import useLocalized from 'CustomHooks/useLocalized';

const getMapCenter = () => {

  // Makes the pin appear on the center of the map
  return [COMPANY_LOCATION[0] + 0.02, COMPANY_LOCATION[1]];
};

const Map = () => {
  return (
    <MapContainer center={getMapCenter()} zoom={13} scrollWheelZoom={false} zoomControl={false} dragging={false}>
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
      />
      <Marker icon={MarkerIcon} position={COMPANY_LOCATION}>
        <Tooltip permanent direction="top">
          <div className="map-popup-container">
            <div className="map-popup-container__title">
              {useLocalized('postal_address')}
            </div>
            <div className="line" />
            <div className="map-popup-container__content" dangerouslySetInnerHTML={{ __html: COMPANY_ADDRESS }} />
          </div>
        </Tooltip>
      </Marker>
    </MapContainer>
  );
}

export default Map;
